
import '@/assets/css/header.scss';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import CloseDialog from "@/components/closeDialog.vue";
import UpdateDomainDialog from "@/components/updateDomainDialog.vue";
import { setLocalAndSession } from '@/utils/utils';

@Component({
    name: 'Header',
    components: {
        CloseDialog,
        UpdateDomainDialog,
    },
})
export default class Header extends Vue {
    private baseUrlIfram = process.env.VUE_APP_URL;
    @Prop(String) private type!: string;
    private moreOverFlag: boolean = false; // 摩尔云菜单展开flag
    private companyOverFlag: boolean = false; // 企业中心菜单展开flag
    private userBoxFlag: boolean = false; // 用户菜单展开flag
    private menuesData = []; // 菜单栏数据
    private exitFlag = false; // 用户退出flag
    private cert: number = 0; // 已认证
    private partnerStatus: number = 3; // 伙伴状态(0, "已过期"),(1, "待签约"), (2, "合作中"), (3, "未加入")
    private useInfo: any = {}; // 用户信息
    private baseUrl: string = '';
    private hasReverseAccount: boolean = false; // 是否可以切换个人企业中心
    private usernameAbb = '';
    private showUpdateDomain: boolean = false;

    @Watch('$store.state.userInfo')
    private storeUserInfo(val: any) {
        this.useInfo = val;
    }
    private created() {
        this.$nextTick(() => {
            // 获取初始菜单栏数据
            this.initData();
            this.useInfo = this.$store.state.userInfo;
            this.cert = this.$store.state.userInfo.certStatus;
            this.partnerStatus = this.$store.state.userInfo.partnerStatus;
            this.hasReverseAccount = this.$store.state.userInfo.hasReverseAccount;
            const userName = this.$store.state.userInfo.username;
            if (userName.length > 1) {
                this.usernameAbb =
                userName.substring(0, 1) + '***' + userName.substring(userName.length - 1, userName.length);
            } else {
                this.usernameAbb = '***';
            }
            if (process.env.NODE_ENV === 'development') {
                this.baseUrl = '/apiProxy';
            }
            // const url = window.location.href;
            // if (url.indexOf(`www.morewiscloud.com`) > -1) {
            //     this.showUpdateDomain = true;
            // }
        });
    }
    private menuDisplayed(menuName: any) {
        // 认证成功并是生态伙伴才有报备客户菜单
        // 伙伴状态(0, "已过期"),(1, "待签约"), (2, "合作中"), (3, "未加入"), (3, "未加入")
        if (this.cert === 2 && menuName === 'reportCustomer' && this.partnerStatus !== 3 && this.partnerStatus !== 4) {
            return true;
        // 后台配置允许的才有驱动开发（合作伙伴也有驱动开发）
        } else if (menuName === 'driveDevelopment' && this.cert === 2
            && this.partnerStatus !== 3 && this.partnerStatus !== 4) {
            return true;
        } else if (menuName !== 'reportCustomer' && menuName !== 'driveDevelopment') {
            return true;
        }
        return false;
    }

    // 获取初始数据
    private initData() {
        this.menuesData = this.$store.state.menuesData; // 获取菜单栏数据
        const data = [...this.menuesData];
        data.shift();
        this.menuesData = data; // 得到去掉首页的菜单栏
        // console.log("this.$store.state.renzheng=========>", this.$store.state.renzheng);
    }

    // 去认证
    private openCert() {
        this.$router.push({ path: "/company/Certification",
        query: { isBack: '0', isCert: '1' }}).catch((err) => err);
        this.userBoxFlag = false;
    }

    // 去消息列表
    private openMessage() {
        this.$router.push({ path: "/company/message"}).catch((err) => err);
        this.userBoxFlag = false;
    }

    // 修改密码
    private changePassword() {
        if (this.useInfo.userType === 2) {
            this.$router.push({ path: "/company/security"}).catch((err) => err);
        } else if (this.useInfo.userType === 1) {
            this.$router.push({ path: "/person/perSecurity"}).catch((err) => err);
        }
        this.userBoxFlag = false;
    }

    // 我的订单
    private gotoOrder(orderStatus: string) {
        if (this.useInfo.userType === 2) {
            this.$router.push({ path: "/company/myOrder", query: {orderStatus}}).catch((err) => err);
        }
        this.userBoxFlag = false;
    }

    // 我的应用
    private gotoApp() {
        if (this.useInfo.userType === 2) {
            this.$router.push({ path: "/sale/myApp"}).catch((err) => err);
        }
        this.userBoxFlag = false;
    }

    // 用户退出
    private exitDialog() {
        this.exitFlag = true;
        this.userBoxFlag = false;
    }
    // 接收关闭弹窗传回来的值
    private propCloseMsg(num: number) {
        this.exitFlag = false;
    }
    // 点击水平导航栏里的菜单跳转
    private gotoPath(path: string) {
        this.$router.push(`/${this.type}/` + path).catch((err) => err);
        this.$store.commit('saveCurrPath', path);
        // 水平导航栏隐藏
        this.companyOverFlag = false;
    }
    // 摩尔云菜单展开与隐藏
    private moreOverEnter(): void {
        this.companyOverFlag = false;
        this.moreOverFlag = true;
    }
    // 企业中心菜单展开与隐藏
    private companyOverEnter(typeNum: number): void {
        if (typeNum === 1 && this.type === "sale") {
            return;
        }
        if (typeNum === 2 && (this.type === "company" || this.type === "person")) {
            return;
        }
        this.companyOverFlag = true;
        this.moreOverFlag = false;
    }
    // 用户菜单展开与隐藏
    private userBoxSH(): void {
        this.userBoxFlag = !this.userBoxFlag;
    }
    // 打开销售中心
    private openSaleOrCompany(typeNum: number): void {
        if (typeNum === 1) {
            if ((this.type === "company" || this.type === "person")) {
                return;
            } else {
                this.$router.push("/company").catch((err) => err);
            }
        }
        if (typeNum === 2) {
            if (this.type === "sale") {
                return;
            } else {
                this.$router.push("/sale/myApp").catch((err) => err);
            }
        }
    }
    // 到各自首页
    private gotoHome(): void {
        if (this.useInfo.userType === 1) {
            this.$router.push("/person").catch((err) => err);
        } else {
            this.$router.push("/company").catch((err) => err);
        }
        this.userBoxFlag = false;
    }
    // 切换用户中心
    private switchUser(): void {
        const userType = this.$store.state.userInfo.userType;
        this.$httpService.postData({}, this.$apiList.switcHover).then((res: any) => {
            this.$message.success('切换成功');

            // 获取当前用户信息
            this.$httpService.getData({type: 'getUserType'}, this.$apiList.userInfo)
            .then((resCurrent: any) => {
                // this.$store.commit('saveUserInfo', resCurrent);
                setLocalAndSession(resCurrent);

                if (resCurrent.userType === 1) {
                    this.$router.push("/person").catch((err) => err);
                } else {
                    this.$router.push("/company").catch((err) => err);
                }
            });
        });
    }
    private gotoCloud() {
        this.$router.push({
            name: "Index",
            params: { reviewLoading: '1'}}).catch((err) => err);
    }
    private gotoNtalk() {
        window.open(process.env.VUE_APP_TALK_URL + '/ntalkWeb/#/morewis?moreNumber=' + this.useInfo.morewisNumber);
    }
    // 跳转
    private navLink(type: number) {
        this.userBoxFlag = false;
        if (type === 1) {
            this.$router.push('/index');
        } else if (type === 2) {
            window.open('https://www.morewis.com/');
        } else if (type === 3) {
            window.open('https://ntalk.morewis.com/web/home');
        } else if (type === 4) {
            // window.location.href = '/mall/index.htm';
            this.$router.push('/mall');
        } else if (type === 5) { // 文档
            if (this.useInfo.userType === 1) {
                this.$router.push("/personDoc").catch((err) => err);
            } else {
                this.$router.push("/companyDoc").catch((err) => err);
            }
        } else if (type === 6) {
            window.open(process.env.VUE_APP_TALK_URL + '/ntalkWeb/#/morewis?moreNumber=' + this.useInfo.morewisNumber);
        } else if (type === 7) {
            this.$router.push({ path: "/company/message"}).catch((err) => err);
        } else if (type === 8) {
            // window.location.href = this.baseUrlIframe + '/developer/index.htm';
            this.$router.push('/developerCenter');
        } else if (type === 9) {
            // window.location.href = this.baseUrlIframe + '/v1/main/learn/index.htm';
            this.$router.push('/experienceCenter');
        } else if (type === 10) {
            // 新手指南
            window.open(process.env.VUE_APP_URL + '/doc/index.htm');
        }
    }
    private gotoCompanyRouter(type: number) {
        this.userBoxFlag = false;
        if (type === 1) {
            this.$router.push('/company/material');
        } else if (type === 2) {
            this.$router.push('/company/security');
        } else if (type === 3) {
            this.$router.push('/company/resource');
        } else if (type === 4) {
            this.gotoOrder('0');
        }
    }
    private gotoPersonRouter(type: number) {
        this.userBoxFlag = false;
        if (type === 1) {
            this.$router.push('/person/perMaterial');
        } else if (type === 2) {
            this.$router.push('/person/perSecurity');
        }
    }
}
