
import { Component, Vue, Prop, Watch, Emit } from 'vue-property-decorator';
@Component({
  name: 'CloseDialog',
})
export default class CloseDialog extends Vue {
    private showDialog: boolean = false; // 弹窗显示与关闭

    @Prop({
        type: Boolean,
        default: false,
    })
    private exitFlag!: boolean; // 接收父组件传过来的值
    @Watch('exitFlag') // 监听父组件传过来的值
    private exitFlagFun(value: boolean) {
        this.showDialog = value;
    }
    // 给父组件传值
    @Emit('closeMsg')
    private closeTodo(num: number): number {
        return num;
    }
    //  确定按钮
    private closeDialog(num: number) {
        if (num === 3) {
            this.$httpService.postData({}, '/apiProxy/api/frontend/passport/logout').then((res: any) => {
                if (res) {
                    this.showDialog = false;
                    this.closeTodo(num); // 给父组件传值
                    // 回到登录页面
                    this.$router.push("/Login").catch((err) => err);
                    // this.$router.push({
                    //     name: "Index",
                    //     params: { reviewLoading: '1'}}).catch((err) => err);
                    // 清除cookie
                    const seesionToken = localStorage.getItem('session_token');
                    const legacyieName = localStorage.getItem('legacy_cookieName');
                    const legacyDomain = localStorage.getItem('legacy_cookieDomain');
                    document.cookie = `${legacyieName}=${seesionToken};domain=${legacyDomain};path=/;max-age=0`;
                    // 清除各种缓存数据
                    localStorage.clear();
                }
            });
        } else {
            this.showDialog = false;
            this.closeTodo(num); // 给父组件传值
        }
    }
    // 右上角的关闭按钮
    private beforeclose() {
        this.showDialog = false;
        this.closeTodo(1); // 给父组件传值
    }

}
